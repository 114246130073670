import type { ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

export function Divider({ children }: Props) {
  return (
    <div className="my-6 flex items-center gap-4">
      <hr className="grow rounded-full border-t-3 border-white md:border-epspacedust" />
      <span className="text-xs font-bold text-white md:text-epspacedust">{children}</span>
      <hr className="grow rounded-full border-t-3 border-white md:border-epspacedust" />
    </div>
  );
}
