import { useState } from 'react';

import {
  type RememberedSchool,
  getRememberedSchools,
  removeSchool,
  saveSchool,
} from '../store/schools';

export function useRememberedSchools() {
  const [rememberedSchools, setRememberedSchools] = useState(getRememberedSchools());

  function addRememberedSchool(school: RememberedSchool, update = true) {
    saveSchool(school);
    if (update) {
      setRememberedSchools(getRememberedSchools());
    }
  }

  function removeRememberedSchool(school: RememberedSchool, update = true) {
    removeSchool(school);
    if (update) {
      setRememberedSchools(getRememberedSchools());
    }
  }

  return { rememberedSchools, addRememberedSchool, removeRememberedSchool };
}
