import { createRoot } from 'react-dom/client';

import { setUpRive } from '../util/rive';
import { LoginPage } from './LoginPage';

setUpRive();

const appEl = document.getElementById('app');
if (appEl) {
  const root = createRoot(appEl);
  root.render(<LoginPage />);
}
