export function getLoginChallenge(): string {
  const divElement = document.getElementById('login_challenge') as HTMLInputElement | null;
  if (!divElement) {
    // eslint-disable-next-line no-console
    console.error('Could not find login challenge');
    return '';
  }

  return divElement.value;
}

export function getConsentChallenge(): string {
  const divElement = document.getElementById('consent_challenge') as HTMLInputElement | null;
  if (!divElement) {
    // eslint-disable-next-line no-console
    console.error('Could not find consent challenge');
    return '';
  }

  return divElement.value;
}
