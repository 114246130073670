import clsx from 'clsx';
import type { ReactNode } from 'react';

interface Props {
  children: ReactNode;
  active?: boolean;
  mode?: 'default' | 'danger';
  onMouseEnter: () => void;
  onMouseLeave: () => void;
}

export function Card({ children, active, ...props }: Props) {
  const mode = props.mode ?? 'default';

  return (
    <div
      className={clsx(
        active && 'bg-epgradient-secondary',
        mode === 'danger' && 'border-epmars hover:bg-epmmars-200',
        mode === 'default' && 'border-transparent hover:border-epinfrapurple',
        'ep-card-shadow cursor-pointer rounded-xl border-3 bg-white p-6 text-xl font-medium transition-all duration-300 ease-epease',
      )}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
    >
      {/* Needs to be a different div to stop transition animation */}
      <div className={clsx(active && 'text-white')}>{children}</div>
    </div>
  );
}
