import { FloatingPortal } from '@floating-ui/react';
import clsx from 'clsx';
import type { ReactNode } from 'react';
import { CSSTransition } from 'react-transition-group';
import { ENTERING, EXITED, EXITING } from 'react-transition-group/Transition';

import { useFloatingForAutocomplete } from './useFloating';

interface Props<T> {
  id: string;
  search: (value: string) => Promise<T[]>;
  onSelectValue: (value: T | null) => void;
  formatSearchResult: (value: T) => ReactNode;
}

export function AutocompleteInput<T>({ id, search, onSelectValue, formatSearchResult }: Props<T>) {
  const {
    isOpen,
    inputProps,
    searchResults,
    refs,
    listRef,
    floatingStyles,
    getReferenceProps,
    getFloatingProps,
    activeIndex,
  } = useFloatingForAutocomplete({
    onSearch: async (value: string) => await search(value),
    onSelectValue,
  });

  return (
    <>
      <div
        ref={refs.setReference}
        className={clsx(
          'w-full rounded-lg border-3 border-white bg-white p-3 transition-colors duration-300 ease-epease hover:border-epminfrapurple-100 hover:bg-epminfrapurple-100 focus:border-epminfrapurple-100 focus:bg-white',
        )}
        {...getReferenceProps()}
      >
        <input
          className="w-full bg-transparent outline-none"
          id={id}
          autoComplete="off"
          {...inputProps}
        />
      </div>
      <CSSTransition
        in={isOpen}
        addEndListener={(done) => {
          refs.floating.current?.addEventListener('transitionend', done, false);
        }}
        unmountOnExit
        nodeRef={refs.floating}
        classNames={{
          appear: 'translate-y-10',
        }}
      >
        {(state) => (
          <FloatingPortal>
            <div
              className={clsx(
                state === ENTERING || state === EXITING || state === EXITED
                  ? 'translate-y-10 opacity-0'
                  : 'translate-y-0 opacity-100',
                'ep-card-shadow flex flex-col gap-3 rounded-lg bg-white p-3 transition-all duration-200 ease-epease',
              )}
              ref={refs.setFloating}
              style={floatingStyles}
              {...getFloatingProps()}
            >
              {searchResults.map((result, idx) => (
                <div
                  key={JSON.stringify(result)}
                  className={clsx(
                    activeIndex === idx && 'bg-epminfrapurple-100',
                    'cursor-pointer rounded-[0.25rem] p-2 hover:bg-epminfrapurple-100',
                  )}
                  onClick={() => onSelectValue(result)}
                  ref={(element) => {
                    // eslint-disable-next-line security/detect-object-injection
                    listRef.current[idx] = element;
                  }}
                >
                  {formatSearchResult(result)}
                </div>
              ))}
            </div>
          </FloatingPortal>
        )}
      </CSSTransition>
    </>
  );
}
