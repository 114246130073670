import { faSchool } from '@fortawesome/free-solid-svg-icons/faSchool';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';

import { Card } from '../common/Card';
import { useToggle } from '../hooks/useToggle';
import type { RememberedSchool } from '../store/schools';
import { TrashIcon } from './TrashIcon';

interface Props {
  school: RememberedSchool;
  isEditing: boolean;
}

export function RememberedSchoolCard({ school, isEditing }: Props) {
  const {
    isActive: isHoveringCard,
    setActive: startHoveringCard,
    setInactive: stopHoveringCard,
  } = useToggle(false);
  return (
    <Card
      mode={isEditing ? 'danger' : 'default'}
      onMouseEnter={startHoveringCard}
      onMouseLeave={stopHoveringCard}
    >
      <div className="flex h-16 items-center gap-3 overflow-hidden text-start">
        <div
          className={clsx(
            isEditing ? 'translate-y-9' : '-translate-y-9',
            'flex flex-col gap-4 transition-all duration-200 ease-epease',
          )}
        >
          <TrashIcon className="size-14" hover={isHoveringCard} />
          <FontAwesomeIcon icon={faSchool} className="size-14 text-epinfrapurple" />
        </div>
        {school.name}
      </div>
    </Card>
  );
}
