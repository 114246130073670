export function setUpRive() {
  import('@rive-app/react-canvas-lite')
    // eslint-disable-next-line @typescript-eslint/naming-convention
    .then(({ RuntimeLoader }) => {
      RuntimeLoader.setWasmUrl('/assets/rive/rive.wasm');
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.error('Failed to load Rive runtime:', error);
    });
}
