import clsx from 'clsx';
import type { ReactNode } from 'react';

interface Props {
  forId: string;
  children: ReactNode;
  className?: string;
}

export function Label({ forId, children, className }: Props) {
  return (
    <label htmlFor={forId} className={clsx(className, 'text-xs font-bold')}>
      {children}
    </label>
  );
}
