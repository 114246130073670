import { useRef, useState } from 'react';

import { ensureError } from '../../common/utils';
import { type SearchedSchool, autocompleteByDomain, autocompleteByName } from '../autocomplete';

interface InitialState {
  error?: string | null;
}

export function useSearchableSchool({ error: initialError }: InitialState) {
  const abortController = useRef(new AbortController());

  const [error, setError] = useState<string | null>(initialError ?? null);

  const [selectedEmail, setSelectedEmail] = useState<(SearchedSchool & { email: string }) | null>(
    null,
  );
  const [selectedSchool, setSelectedSchool] = useState<SearchedSchool | null>(null);

  async function searchByMail(mail: string): Promise<(SearchedSchool & { email: string })[]> {
    // Cancel previous requests
    abortController.current.abort();

    // use new abort controller for new request
    abortController.current = new AbortController();
    const [username, domain] = mail.split('@');
    if (!domain) {
      return [];
    }

    try {
      const data = await autocompleteByDomain(domain, { signal: abortController.current.signal });
      return data
        .filter((school) => !!school.mailDomain)
        .map((school) => ({ ...school, email: `${username}@${school.mailDomain}` }));
    } catch (error) {
      if (error instanceof DOMException && error.name === 'AbortError') {
        // ignore abortions
        return [];
      }
      setError(ensureError(error).message);
      return [];
    }
  }

  async function searchByName(name: string): Promise<SearchedSchool[]> {
    // Cancel previous requests
    abortController.current.abort();

    // use new abort controller for new request
    abortController.current = new AbortController();

    try {
      const data = await autocompleteByName(name, { signal: abortController.current.signal });
      return data;
    } catch (error) {
      if (error instanceof DOMException && error.name === 'AbortError') {
        // ignore abortions
        return [];
      }
      setError(ensureError(error).message);
      return [];
    }
  }

  return {
    error,
    searchByMail,
    searchByName,
    selectedEmail,
    selectedSchool,
    setSelectedEmail,
    setSelectedSchool,
  };
}
