import { useState } from 'react';

export function useToggle(defaultValue?: boolean) {
  const [isActive, setIsActive] = useState(defaultValue ?? false);

  function setActive() {
    setIsActive(true);
  }

  function setInactive() {
    setIsActive(false);
  }

  function toggle() {
    setIsActive(!isActive);
  }

  return { isActive, setActive, setInactive, toggle };
}
