'use client';

import type { IconDefinition } from '@fortawesome/fontawesome-common-types';
import clsx from 'clsx';
import { type ReactNode, lazy } from 'react';

import { assertUnreachable } from './utils';

interface Props {
  href?: string;
  externalLink?: string;
  children?: ReactNode;
  iconBefore?: ReactNode | IconDefinition;
  iconAfter?: ReactNode | IconDefinition;
  value?: string;
  name?: string;
  /**
   * default: primary
   */
  mode?: 'tertiary' | 'primary' | 'ghost' | 'secondary' | 'warning';
  /**
   * default: md
   */
  size?: 'xs' | 'sm' | 'md' | 'lg';
  /**
   * default: Link
   */
  element?: 'a' | 'button' | 'submit' | 'Link';
  /**
   * default: inline-flex
   */
  inline?: 'inline-flex' | 'flex';
  disabled?: boolean;
  active?: boolean;
  onClick?: () => unknown;
  className?: string;
  textColor?: string;
  activeBackground?: string;
}

export default function EPButton(props: Props) {
  const element = props.element ?? 'Link';
  const linkHref = props.href ?? props.externalLink;

  const mode = props.mode ?? 'primary';
  const size = props.size ?? 'md';

  let className = props.disabled ? 'cursor-default' : 'cursor-pointer';
  let textColor = props.textColor ?? null;
  let padding = '';
  let activeBackground = props.activeBackground ?? null;

  if (props.children) {
    switch (size) {
      case 'xs':
        padding = 'px-5 py-2';
        break;
      case 'sm':
        padding = 'px-8 py-3';
        break;
      case 'md':
        padding = 'px-10 py-5';
        break;
      case 'lg':
        padding = 'px-16 py-7';
        break;
      default:
        assertUnreachable(size);
    }
  } else {
    padding = 'p-4';
  }

  if (props.disabled) {
    switch (mode) {
      case 'primary':
      case 'secondary':
      case 'warning':
        className += ' bg-epmspacedust-300';
        textColor ??= 'text-white';
        break;
      case 'tertiary':
        className += ' border-2 border-epmspacedust-300 bg-transparent';
        textColor ??= 'text-epmspacedust-300';
        break;
      case 'ghost':
        className += ' bg-transparent';
        textColor ??= 'text-epmspacedust-300';
        break;
      default:
        assertUnreachable(mode);
    }
  } else {
    switch (mode) {
      case 'primary':
        className +=
          ' ' +
          clsx(
            !props.active && 'from-epgradientstart to-epgradientend',
            'bg-gradient-to-r hover:from-epinfrapurple hover:to-epinfrapurple ',
          );
        textColor ??= 'text-white';
        activeBackground ??= clsx(
          props.active && 'from-epminfrapurple-600 to-epminfrapurple-600',
          'active:from-epminfrapurple-600 active:to-epminfrapurple-600',
        );
        break;
      case 'secondary':
        className +=
          ' ' + clsx(!props.active && 'bg-epinfrapurple', ' hover:bg-epminfrapurple-400 ');
        textColor ??= 'text-white';
        activeBackground ??= clsx(
          props.active && 'bg-epminfrapurple-600',
          'active:bg-epminfrapurple-600',
        );
        break;
      case 'tertiary':
        className +=
          ' ' +
          clsx(
            !props.active && 'bg-transparent',
            'border-2 border-epinfrapurple hover:bg-epminfrapurple-100',
          );
        textColor ??= (props.active ? 'text-white' : 'text-epinfrapurple') + ' active:text-white';
        activeBackground ??= clsx(
          props.active && 'bg-epminfrapurple-600',
          'active:bg-epminfrapurple-600',
        );
        break;
      case 'ghost':
        className += ' ' + clsx(!props.active && 'bg-transparent', 'hover:bg-epminfrapurple-100');
        textColor ??= (props.active ? 'text-white' : 'text-epinfrapurple') + ' active:text-white';
        activeBackground ??= clsx(
          props.active ? 'bg-epminfrapurple-600' : 'bg-transparent',
          'active:bg-epminfrapurple-600',
        );
        break;
      case 'warning':
        className += ' ' + clsx(!props.active && 'bg-epmars', 'hover:bg-epmmars-400');
        textColor ??= 'text-white';
        activeBackground ??= clsx(props.active && 'bg-epmmars-600', 'active:bg-epmmars-600');
        break;
      default:
        assertUnreachable(mode);
    }
  }

  if (!props.children) {
    className += ' rounded-full';
  } else {
    className += ' rounded-[6rem]';
  }

  const commonClassName = clsx(
    props.inline === 'flex' ? 'flex' : 'inline-flex',
    textColor,
    padding,
    activeBackground,
    'items-center gap-3 text-sm font-medium transition-all duration-150',
  );

  if (element === 'button' || element === 'submit') {
    return (
      <button
        onClick={props.onClick}
        className={clsx(props.className, commonClassName, className)}
        disabled={props.disabled}
        type={element === 'submit' ? 'submit' : 'button'}
        name={props.name}
        value={props.value}
      >
        <Icon icon={props.iconBefore} />
        {props.children}
        <Icon icon={props.iconAfter} />
      </button>
    );
  } else if (element === 'Link' && linkHref) {
    return (
      <a
        href={linkHref}
        target={props.externalLink ? '_blank' : ''}
        onClick={props.onClick}
        className={clsx(props.className, className, commonClassName)}
      >
        <Icon icon={props.iconBefore} />
        {props.children}
        <Icon icon={props.iconAfter} />
      </a>
    );
  } else {
    return (
      <a
        href={props.href ?? props.externalLink}
        target={props.externalLink ? '_blank' : ''}
        onClick={props.onClick}
        className={clsx(props.className, className, commonClassName)}
      >
        <Icon icon={props.iconBefore} />
        {props.children}
        <Icon icon={props.iconAfter} />
      </a>
    );
  }
}

// Lazy load FontAwesomeIcon to reduce bundle size
const FaIcon = lazy(async () => ({
  default: (await import('@fortawesome/react-fontawesome')).FontAwesomeIcon,
}));

function Icon({ icon }: { icon?: IconDefinition | ReactNode }) {
  if (!icon) {
    return null;
  }

  if (isFontawesomeIconDefinition(icon)) {
    return <FaIcon icon={icon} className="size-4" />;
  }

  return <>{icon}</>;
}

function isFontawesomeIconDefinition(icon: unknown): icon is IconDefinition {
  return 'icon' in (icon as IconDefinition);
}
