import { faTrash } from '@fortawesome/free-solid-svg-icons/faTrash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { Suspense, lazy, useEffect } from 'react';

export function TrashIcon({ className, hover }: { className?: string; hover: boolean }) {
  return (
    <Suspense
      fallback={<FontAwesomeIcon className={clsx(className, 'text-epmars')} icon={faTrash} />}
    >
      <TrashIconAsync className={className} hover={hover} />
    </Suspense>
  );
}

// lazy load rive
const TrashIconAsync = lazy(async () => {
  const { Fit, Layout, useRive } = await import('@rive-app/react-canvas-lite');
  return {
    default: function TrashIconAsync({ className, hover }: { className?: string; hover: boolean }) {
      const { RiveComponent, rive } = useRive({
        src: '/rive/trash.riv',
        stateMachines: 'Open & Close',
        autoplay: true,
        layout: new Layout({ fit: Fit.Cover }),
      });

      useEffect(() => {
        if (!rive) {
          return;
        }

        const input = rive
          .stateMachineInputs('Open & Close')
          .find((input) => input.name === 'hover');
        if (!input) {
          // eslint-disable-next-line no-console
          console.error('Rive: Could not find input with name hover');
          return;
        }

        input.value = hover;
      }, [hover]);

      return <RiveComponent className={className} />;
    },
  };
});
