export interface RememberedSchool {
  name: string;
  identityProviderId: string;
}

export function getRememberedSchools(): RememberedSchool[] {
  const schools = localStorage.getItem('schools');
  if (!schools) {
    return [];
  }

  const ret = JSON.parse(schools) as unknown;
  if (!Array.isArray(ret)) {
    // eslint-disable-next-line no-console
    console.error('Invalid schools in local storage. Is not array.');
    return [];
  }

  if (
    ret.some(
      (school) =>
        typeof school !== 'object' ||
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        typeof school.name !== 'string' ||
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        typeof school.identityProviderId !== 'string',
    )
  ) {
    // eslint-disable-next-line no-console
    console.error('Invalid schools in local storage. Some schools are invalid.');
    return [];
  }

  return ret as RememberedSchool[];
}

export function saveSchool(school: RememberedSchool): void {
  const existingSchools = getRememberedSchools();
  if (existingSchools.find((s) => s.identityProviderId === school.identityProviderId)) {
    return; // Do not save duplicates
  }
  existingSchools.push(school);
  localStorage.setItem('schools', JSON.stringify(existingSchools));
}

export function removeSchool(school: RememberedSchool): void {
  const existingSchools = getRememberedSchools();
  const newArray = existingSchools.filter(
    (s) => s.identityProviderId !== school.identityProviderId,
  );
  localStorage.setItem('schools', JSON.stringify(newArray));
}
