import { clsx } from 'clsx';
import { type ReactNode, Suspense, lazy, useEffect } from 'react';

interface Props {
  children: ReactNode;
  className?: string;
  logoClassName?: string;
  bgClassName?: string;
}

export function Layout({ children, className, logoClassName, bgClassName }: Props) {
  return (
    <div className={clsx(className, bgClassName ?? 'bg-eduplaces', 'min-h-dvh w-full')}>
      <img
        src="/img/logo-white.svg"
        alt="Logo"
        className={clsx(logoClassName, 'absolute left-10 top-10 hidden h-auto w-28 md:block')}
      />
      <MobileLogo className={logoClassName} />
      {children}
    </div>
  );
}

function MobileLogo({ className }: { className?: string }) {
  return (
    <Suspense
      fallback={
        <img
          src="/img/logo-mobile.svg"
          alt="Logo"
          className={clsx(className, 'absolute left-4 top-10 size-12 md:hidden')}
        />
      }
    >
      <RiveMobileIcon className={className} />
    </Suspense>
  );
}

// lazy load rive
const RiveMobileIcon = lazy(async () => {
  const { Fit, Layout, useRive } = await import('@rive-app/react-canvas-lite');
  return {
    default: function RiveMobileIcon({ className }: { className?: string }) {
      const { RiveComponent, rive } = useRive({
        src: '/rive/clicking_edu.riv',
        stateMachines: 'Clicking Edu',
        autoplay: true,
        layout: new Layout({ fit: Fit.FitHeight }),
      });

      useEffect(() => {
        if (!rive) {
          return;
        }

        const input = rive
          .stateMachineInputs('Clicking Edu')
          .find((input) => input.name === 'click');
        if (!input) {
          return;
        }
      }, [rive]);

      return (
        <RiveComponent className={clsx(className, 'absolute left-2 top-8 size-16 md:hidden')} />
      );
    },
  };
});
