export function getServerSideErrors(): string | null {
  const divElement = document.getElementById('error');
  if (!divElement) {
    return null;
  }

  return divElement.textContent;
}

export function getServerSideErrorDescription(): string | null {
  const divElement = document.getElementById('error_description');
  if (!divElement) {
    return null;
  }

  return divElement.textContent;
}
