import clsx from 'clsx';
import type { ReactNode } from 'react';

interface Props {
  children: ReactNode;
  className?: string;
  size?: 'md' | 'lg';
}

export function Title({ children, className, size = 'md' }: Props) {
  return (
    <h1
      className={clsx(
        className,
        size === 'md' && 'text-4xl font-semibold md:text-2xl',
        size === 'lg' && 'text-4xl font-bold md:text-5xl',
        'leading-tight text-white md:text-epinfrapurple',
      )}
    >
      {children}
    </h1>
  );
}

export function Subtitle({ children, className }: { children: ReactNode; className?: string }) {
  return (
    <h2 className={clsx(className, 'leading-tight text-white md:text-epspacedust')}>{children}</h2>
  );
}
