interface NestedTranslationData {
  [key: string]: string | NestedTranslationData;
}
type TranslationData = Record<string, NestedTranslationData>;

interface RealTranslationData {
  // eslint-disable-next-line @typescript-eslint/consistent-type-imports
  login: typeof import('../../../i18n/de/login.json');
  // eslint-disable-next-line @typescript-eslint/consistent-type-imports
  consent: typeof import('../../../i18n/de/consent.json');
  // eslint-disable-next-line @typescript-eslint/consistent-type-imports, @typescript-eslint/naming-convention
  consent_new: typeof import('../../../i18n/de/consent_new.json');
  // eslint-disable-next-line @typescript-eslint/consistent-type-imports
  error: typeof import('../../../i18n/de/error.json');
  // eslint-disable-next-line @typescript-eslint/consistent-type-imports
  scope: typeof import('../../../i18n/de/scope.json');
}

type Dot<T extends string, U extends string> = '' extends U ? T : `${T}.${U}`;
type GetKeys<T> = T extends string
  ? ''
  : {
      [K in keyof T & string]: T[K] extends string ? K : Dot<K, GetKeys<T[K]>>;
    }[keyof T & string];

type TranslationKey<T extends keyof RealTranslationData> = GetKeys<RealTranslationData[T]>;

let translationData: TranslationData | null = null;
export function getTranslationData(): TranslationData {
  if (null !== translationData) {
    return translationData;
  }

  const element = document.getElementById('translation-data');
  if (!element) {
    return {};
  }

  return (translationData = JSON.parse(element.innerHTML) as TranslationData);
}

export function useTranslations<T extends keyof RealTranslationData>(scope: T) {
  // eslint-disable-next-line security/detect-object-injection
  const data = getTranslationData()[scope];
  return (key: TranslationKey<T>): string => {
    const parts = key.split('.');
    const firstPart = parts.shift();
    if (firstPart === undefined) {
      throw new Error('Invalid translation key');
    }
    // eslint-disable-next-line security/detect-object-injection
    let currentPart: string | NestedTranslationData | undefined = data[firstPart];
    while (parts.length > 0 && typeof currentPart !== 'string') {
      const nextPart = parts.shift();
      if (nextPart === undefined) {
        throw new Error('Next part cannot be undefined');
      }
      // eslint-disable-next-line security/detect-object-injection
      currentPart = currentPart[nextPart];
    }

    return currentPart as string;
  };
}
