export interface SearchedSchool {
  name: string;
  identityProviderId: string;
  mailDomain: string | null;
  address: { zipCode: string; city: string } | null;
}

async function fetchAutocomplete(
  queryParam: string,
  value: string,
  { signal }: { signal: AbortSignal },
): Promise<SearchedSchool[]> {
  const searchParams = new URLSearchParams({ [queryParam]: value.trim() });
  const response = await fetch('/login/provider-autocomplete?' + searchParams.toString(), {
    signal,
  });
  if (!response.ok) {
    throw new Error(`Error fetching autocomplete results: ${response.statusText}`);
  }
  return (await response.json()) as Promise<SearchedSchool[]>;
}

export function autocompleteByName(
  name: string,
  options: { signal: AbortSignal },
): Promise<SearchedSchool[]> {
  if (name.length < 3) {
    return Promise.resolve([]);
  }
  return fetchAutocomplete('name', name, options);
}

export function autocompleteByDomain(
  domain: string,
  options: { signal: AbortSignal },
): Promise<SearchedSchool[]> {
  if (domain.length < 3) {
    return Promise.resolve([]);
  }
  return fetchAutocomplete('domain', domain, options);
}
