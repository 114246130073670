import clsx from 'clsx';
import type { ReactNode } from 'react';

interface Props {
  children: ReactNode;
  className?: string;
}

export function ContentBox({ children, className }: Props) {
  return (
    <div
      className={clsx(
        className,
        'md:bg-epgradient-glass p-4 max-md:w-full md:min-w-[34rem] md:rounded-[4rem] md:p-10 md:pt-12 md:backdrop-blur-lg',
      )}
    >
      {children}
    </div>
  );
}
