export function assertUnreachable(value: never): never {
  throw new Error(
    'This should be unreachable. This should never happen. Value: ' +
      JSON.stringify(value as unknown),
  );
}
export function ensureError(value: unknown): Error {
  if (value instanceof Error) return value;

  let stringified = '[Unable to stringify the thrown value]';
  try {
    stringified = JSON.stringify(value);
  } catch {}

  const error = new Error(
    `This value was thrown as is, not through an Error: type="${typeof value}" (${stringified})`,
  );
  return error;
}

/**
 * Groups an array of values by a key function into a map
 */
export function groupBy<K extends string, V>(
  values: readonly V[],
  keyFunc: (activation: V) => K,
): Map<K, V[]> {
  const ret = new Map<K, V[]>();
  values.reduce((acc, value) => {
    const key = keyFunc(value);
    const array = acc.get(key) ?? [];
    array.push(value);
    acc.set(key, array);

    return acc;
  }, ret);

  return ret;
}
